<template>
  <v-app style="background: #f6f9fc">
    <div class="mb-60 baojia-box liucheng-top rounded-lg">
      <v-card class="pa-4" flat>
        <div class="d-flex align-center text-center flex-between">
          <div class="d-flex text-center">
            <v-avatar size="60" class="mr-8">
              <v-img
                v-if="userinfos && userinfos.avatar"
                lazy-src="@/assets/images/detail/red-box.png"
                width="60"
                :src="userinfos.avatar"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-else
                lazy-src="@/assets/images/detail/user.png"
                width="60"
                class=""
                src="@/assets/images/detail/user.png"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <div class="text-left">
              <p v-if="userinfos">{{ userinfos.nick_name }}</p>
              <p>
                {{ $t("累计收益：") }} <span class="text-sharered font-weight-bold">¥{{totalm}}</span>
              </p>
            </div>
          </div>
          <v-btn depressed  class="white--text" color="primary" @click="WithdrawalFn">{{
            $t("现金提现")
          }}</v-btn>
        </div>
        <v-row>
          <v-col xl="4" lg="4" md="12" sm="12">
            <v-card flat class="pa-4 d-flex align-center" color="#F7F7F7">
              <div>
                <v-img
                  width="52"
                  class="mr-4"
                  src="@/assets/images/person/wallet1.png"
                ></v-img>
              </div>
              <div class="text-left">
                <div class="mb-2 text-h5 font-weight-bold">¥ {{moneyinfo.ktx_money}}</div>
                <div>{{ $t("钱包余额") }}</div>
              </div>
            </v-card>
          </v-col>
          <v-col xl="4" lg="4" md="12" sm="12">
            <v-card flat class="pa-4 d-flex align-center" color="#F7F7F7">
              <div>
                <v-img
                  class="mr-4"
                  width="52"
                  src="@/assets/images/person/wallet2.png"
                ></v-img>
              </div>
              <div class="text-left">
                <div class="mb-2 text-h5 font-weight-bold">¥ {{moneyinfo.txz_money}}</div>
                <div>{{ $t("提现中的金额") }}</div>
              </div>
            </v-card>
          </v-col>
          <v-col xl="4" lg="4" md="12" sm="12">
            <v-card flat class="pa-4 d-flex align-center" color="#F7F7F7">
              <div>
                <v-img
                  class="mr-4"
                  width="52"
                  src="@/assets/images/person/wallet3.png"
                ></v-img>
              </div>
              <div class="text-left">
                <div class="mb-2 text-h5 font-weight-bold">¥ {{moneyinfo.ytx_money}}</div>
                <div>{{ $t("已提现金额") }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat class="mt-6 pa-4">
        <!-- title -->
        <div class="d-flex flex-between mb-4">
          <div style="width:100px" class="text-body-1 font-weight-bold">{{ $t("金额明细") }}</div>
          <div class="text-right mr-4">
            <v-row>
              <v-col xl="4" lg="4" md="12" sm="12">
                <v-select
                  :items="typelist"
                  hide-details
                  dense
                  outlined
                  width="100"
                  v-model="searchObj.shop_id"
                  :label="$t('类型:')"
                  item-value="id"
                  item-text="name"
                  @change="getOrdertype"
                ></v-select>
              </v-col>
              <v-col xl="4" lg="4 " md="12" sm="12">
                <v-select
                  :items="timelist"       
                  hide-details
                  dense
                  outlined
                  v-model="searchObj.time"
                  :label="$t('时间：')"
                  item-value="id"
                  item-text="name"
                  @change="getOrdertime"
                ></v-select>
              </v-col>
              <v-col xl="4" lg="4" md="12" sm="12">
                <v-card
                  flat
                  class="pl-4 d-flex align-center flex-between"
                  color="#F2F2F2"
                  width="100%"
                >
                  <input
                    height="32"
                    :placeholder="$t('搜索关键词')"
                    class=""
                    v-model="keyword"
                    @keydown.enter="toserach"
                  />
                  <v-btn
                    class=""
                    height="36"
                    width="60"
                    depressed
                    color="primary"
                    @click="toserach"
                  >
                    <v-icon color="#fff" size="28">mdi-magnify</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="datalist"
          :no-data-text="$t('暂无数据')"
          class="elevation-0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.create_time="{ item }">
            <span >{{ item.create_time  | formFilter}}</span>
          </template>
          <template v-slot:item.type="{ item }">
            <span >{{ item.type=='goods' ? $t('商品结算') : $t('提现')}}</span>
          </template>
          <template v-slot:item.change="{ item }">
            <span v-if="item.change=='inc'" class="textred"> +¥ {{ item.amount}}</span>
            <span v-else class="textgreen">-¥ {{ item.amount}}</span>
          </template>
        </v-data-table>
        <div
          v-if="pagearr.pagetotal > 0"
          class="text-center d-flex mt-4 "
          align="center"
        >
          <v-row align="center" class="justify-center">
            <!-- <span>共10页</span>
             -->
            <v-pagination
              v-model="pagearr.page"
              :length="pagearr.pagetotal"
              total-visible="5"
              @input="getpagedata(1)"
            />
            <span class="mr-4 hidden-xs-only">{{ $t("条") }}</span>
            <span class="ml-6 hidden-xs-only">
              {{ $t("跳转至") }}
              <input
                v-model="pagearr.page"
                autocomplete="off"
                style="width: 80px"
                class="border in-border pl-2 pt-1 ml-2 mr-2 pb-1"
                type="number"
                @blur="getpagedata(1)"
              />
              {{ $t("页") }}
            </span>
          </v-row>
        </div>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "userIndex",
  data() {
    return {
      userinfos: null,
      moneyinfo:{},
      totalm:"",
      keyword: "",
      searchObj: {
        shop_id: "",
        time: "",
      },
      param:{},
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 15,
        pagetotal:50,
        total: 0,
      },
      headers: [
        {
          text: this.$t("时间"),
          align: "start",
          sortable: true,
          class: "title-bg",
          value: "create_time",
        },
        { text: this.$t("类型"), value: "type", class: "title-bg" },
        { text: this.$t("详情"), value: "remark", class: "title-bg" },
        { text: this.$t("金额"), value: "change", class: "title-bg" ,align: "end",},
      ],
      datalist: [],
      typelist: [
        { name: this.$t("不限"), id: "" },
        { name: this.$t("商品结算"), id: "goods" },
        { name: this.$t("提现"), id: "withdrawal" },
      ],
      timelist: [
        { name: this.$t("不限"), id: "" },
        { name:this.$t("本周"), id: "week" },
        { name: this.$t("本月"), id: "month" },
      ],
    };
  },
  mounted(){
    this.param["page"] = this.pagearr.page;
    this.param["pagesize"] = this.pagearr.pagenum;
    this.userinfos = this.$store.state.USERINFO;
    this.getMoney()
    this.getamountDetails()
  },
  methods: {
    getMoney(){
      this.$api.user.getMoney().then((res)=>{
        if(res.status=='success'){
          this.moneyinfo=res.data
          this.totalm=Number(res.data.ktx_money)+Number(res.data.txz_money)+Number(res.data.ytx_money)
        }else {
           this.moneyinfo={}
        }
      })
    },
    // 获取金额变动明细
    getamountDetails(){
      this.$api.order.amountDetails(this.param).then((res)=>{
        if(res.status=='success'){
          this.datalist=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
           this.orderdata=[]
           this.pagearr.pagetotal = 0;
        }
      })
    },
    // 搜索
    toserach() {},
    getOrdertype(e) {
      this.param["type"] = e;
      this.getamountDetails()
    },
    getOrdertime(e){
      this.param["time"] = e;
      this.getamountDetails()
    },
    // 点击现金提现
    WithdrawalFn(){
        this.$router.push({ path: '/user/Withdrawa' });
    },
    // 分页跳转
    getpagedata(){

    }
  },
};
</script>

<style lang="scss" scoped>

.flex-between {
  justify-content: space-between;
}
.in-border{
    border: 1px solid #CCCCCC;
}
.text-sharered {
  color: #f63605;
}

.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media (max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}
</style>
<style>
.title-bg {
  background: #f2f2f2 !important;
  color: #f2f2f2;
}
.textred {
  color: #f63605;
}
.textgreen {
  color: #109624;
}
</style>
<style scoped>
/deep/ .v-pagination  .v-pagination__item{
  box-shadow: none;
  background: #F2F2F2;
}
/deep/ .v-pagination__navigation{
  box-shadow: none;
  background: #F2F2F2 !important;
}
</style>